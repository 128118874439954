import {graphql} from 'gatsby'
import React from 'react'
import {useI18next} from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'
import {DescriptionText, TitleText} from '../appearance/styled'
import {getFormatedColorName} from '../helpers/utils'
import {useSelectedColor} from '../uses/context.uses'
import Page from '../components/Layout/Page'
import {PAGES_DEFAULT_COLORS} from '../constants'

const sections = [
    {
        label: 'power',
        width: 340,
        height: 370,
    },
    {
        label: 'muscle',
        width: 400,
        height: 406,
    },
    {
        label: 'hit',
        width: 364,
        height: 364,
    },
    {
        label: 'arrow',
        width: 400,
        height: 400,
    },
    {
        label: 'degree',
        width: 489,
        height: 310,
    },
    {
        label: 'lock',
        width: 304,
        height: 392,
    },
]
const DEFAULT_COLOR = PAGES_DEFAULT_COLORS.who_we_are
const WhoWeAre = ({...p}) => {
    const [color] = useSelectedColor()
    const {t, language} = useI18next()

    return (
        <Page {...p}>
            <SectionsContainer>
                {sections.map((section, i) => {
                    const src = `/images/who_we_are/${getFormatedColorName(color, DEFAULT_COLOR)}/${
                        section.label
                    }.png`
                    const title = t('who_we_are.' + section.label + '.title')
                    const description = t('who_we_are.' + section.label + '.description')
                    return (
                        <Section
                            key={section?.label + '_' + i}
                            lang={language}
                            src={src}
                            noMarginBottom={i === sections?.length - 1}>
                            <Title lang={language}>{title}</Title>
                            <Description>{description}</Description>
                        </Section>
                    )
                })}
            </SectionsContainer>
        </Page>
    )
}

export default WhoWeAre

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`

const SectionsContainer = styled.div``
const Section = styled.div`
    background: url(${({src}) => src});
    &:lang(en) {
        background-position: right bottom;
    }
    &:lang(he) {
        background-position: left bottom;
    }
    background-size: contain;
    background-repeat: no-repeat;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    margin-bottom: ${({noMarginBottom}) => (noMarginBottom ? 0 : 80)}px;
`
const Title = styled(TitleText)``
const Description = styled(DescriptionText)``
